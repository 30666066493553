import { CampaignModel } from './../../../models/Campaign.model';

type CampaignType = keyof CampaignModel;

export function getEnabledFields(status?: string) {

  const _status = status?.toLowerCase();
  if (_status === 'active') {
    return [
      'end_date',
      'merchant_categories',
      'merchants',
      'payment_methods'
    ] as CampaignType[];
  }
  return undefined;
}
