import { Controller } from "react-hook-form";

import { FileUpload } from "../../Inputs";
import { InputFileUploadProps } from "./InputFileUpload.types";
import { useEffect, useRef } from "react";

export function InputFileUpload({
  form,
  rule,
  name,
  ...props
}: InputFileUploadProps) {

  const fileUploadRef = useRef<HTMLDivElement | null>();

  useEffect(() => {
    if (form.formState.isSubmitting) {
      const errorKeys = Object.keys(form.formState.errors);
      if (errorKeys.indexOf(name) === 0) {
        fileUploadRef.current?.scrollIntoView();
      }
    }
  }, [form.formState.isSubmitting]);

  return (
    <Controller
      control={form.control}
      rules={rule}
      name={name}
      render={({ field, fieldState }) => {
        const isError = !!fieldState.error;
        const errorMessage = rule?.required === true
          ? 'Field cannot be empty'
          : fieldState.error?.message?.toString();
        return (
          <FileUpload
            {...props}
            onChangeFile={(_) => field.onChange(_)}
            helperText={isError ? errorMessage : props.helperText}
            required={!!rule?.required}
            value={field.value}
            error={isError}
            ref={(ref) => {
              fileUploadRef.current = ref;
              field.ref(ref);
            }}
          />
        );
      }}
    />
  );
}