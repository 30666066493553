import {
  useDropzone
} from "react-dropzone";
import {
  twMerge
} from "tailwind-merge";
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import {
  hasArray
} from "src/app/utils/array-utils";
import {
  humanFileSize
} from "src/app/utils/input-utils";

import {
  HelperText,
  Label,
  Typography
} from "../../Display";
import {
  DetailIcon,
  PdfInputIcon
} from "../../Icons";
import {
  Button
} from "../Button/Button.page";
import {
  Theme
} from "./FileUpload.theme";
import {
  FileUploadProps,
  FileData
} from "./FileUpload.types";
import {
  getErrorRejection
} from "./FileUpload.utils";

function _FileUpload(
  {
    onChangeFile,
    placeholder,
    helperText,
    className,
    disabled,
    required,
    readOnly,
    value,
    error,
    label,
    ...props
  }: FileUploadProps,
  forwardedRef: Ref<HTMLDivElement>
) {

  const [rejectReations, setRejectReations] = useState<string[]>([]);
  const [fileData, setFileData] = useState<FileData | undefined>(value);

  const linkRef = useRef<HTMLAnchorElement>(null);

  const isDisabled = disabled || readOnly;
  const isError = rejectReations.length > 0 || error;
  const hasFile = !!fileData?.open_link;

  useEffect(() => {
    if (value?.open_link !== undefined) {
      setFileData(value);
    }
  }, [value?.open_link]);

  const handleAcceptedFiles = useCallback(
    ([file]: File[]) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const _fileData = {
          base64: reader.result?.toString(),
          filename: file.name,
          open_link: URL.createObjectURL(file),
          size: humanFileSize(file.size)
        };
        setRejectReations([]);
        onChangeFile?.(_fileData);
        setFileData(_fileData);
      };
    },
    []
  );

  const dropzone = useDropzone({
    ...props,
    disabled: isDisabled,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileData(undefined);
      if (hasArray(fileRejections)) {
        const errorRejection = getErrorRejection(fileRejections, props);
        setRejectReations(errorRejection);
      } else {
        handleAcceptedFiles(acceptedFiles);
      }
    }
  });

  const renderRejectReasonItem = (item: string) => {
    return <HelperText error={isError} key={item}>{item}</HelperText>
  };

  return (
    <div className={twMerge("relative", className)} ref={forwardedRef}>
      {(!!label) && <Label error={isError} {...{ required }}>{label}</Label>}
      <div
        className={twMerge(Theme.media, isError && Theme.mediaError)}
        {...dropzone.getRootProps()}
        role={isDisabled ? "" : "button"}
      >
        <PdfInputIcon className={hasFile ? "text-success-600" : "text-gray-300"} />
        <div className="relative flex-1 min-w-0">
          <Typography className="truncate">
            {hasFile ? fileData?.filename : "Upload File"}
          </Typography>
          <Typography className="truncate" variant="caption">
            {hasFile ? fileData?.size : "PDF Format"}
          </Typography>
          <input {...dropzone.getInputProps()} />
          <a
            className="hidden"
            target="_blank"
            href={fileData?.open_link}
            ref={linkRef}
          />
        </div>
        {(!readOnly) ? (
          <Button variant="outlined" size="sm">
            Select File
          </Button>
        ) : (
          <Button
            className="hover:!bg-transparent"
            onClick={() => linkRef.current?.click()}
            variant="outlined"
            size="sm"
          >
            <DetailIcon className="-mx-0.5" />
          </Button>
        )}
      </div>
      {rejectReations.length > 0 ? (
        <>{rejectReations.map(renderRejectReasonItem)}</>
      ) : (
        <>{(!!helperText) && renderRejectReasonItem(helperText)}</>
      )}
    </div>
  );
}

const FileUpload = forwardRef(_FileUpload);
FileUpload.defaultProps = {
  accept: '.pdf'
} as FileUploadProps;

export { FileUpload }

