import {
  BackButton,
  Button,
  Checkbox,
  Content,
  ContentAction,
  ContentHeader,
  ControlLabel,
  Divider,
  Stack,
} from 'src/app/components'
import {
  HookFormProvider,
  InputDatePicker,
  InputSelect,
  InputSelectAsync,
} from 'src/app/components/HookForm'
import {CampaignFormProps} from './CampaignForm.types'
import {useCampaignFormHooks} from './CampaignForm.hooks'
import {getStoreCategory} from 'src/app/modules/directory/services/StoreCategory.service'
import {getPaymentMethod} from 'src/app/modules/settings/services/PaymentMethod.service'
import {getEnabledFields} from './CampaignForm.utils'
import InputSelectMall from 'src/app/components/HookForm/InputSelect/InputSelect.mall'
import SelectAllCheckbox from 'src/app/components/Inputs/SelectAllCheckbox/SelectAllCheckbox.page'
import isEmpty from 'lodash/isEmpty'
export function CampaignForm(props: CampaignFormProps) {
  const state = useCampaignFormHooks()
  const enabledFields = getEnabledFields(state.campaign.data?.status)

  return (
    <>
      <BackButton />
      <Content>
        <ContentHeader title={props.title} />
        <HookFormProvider
          mutation={state.saveCampaign}
          enables={enabledFields}
          query={state.campaign}
          form={state.form}
        >
          <Stack className='p-6'>
            <ControlLabel label='Mall'>
              <InputSelectMall form={state.form} rule={{required: true}} />
            </ControlLabel>
            <ControlLabel label='Period' required={true}>
              <Stack direction='row' items='center'>
                <InputDatePicker
                  placeholder='Select start date'
                  minDate={state.state.minPeriod.minStartDate}
                  rule={{required: true}}
                  name='start_date'
                  form={state.form}
                />
                <Divider className='w-[50px] border-t-2' color='primary' />
                <InputDatePicker
                  placeholder='Select end date'
                  minDate={state.state.minPeriod.minEndDate}
                  rule={{required: true}}
                  name='end_date'
                  form={state.form}
                />
              </Stack>
            </ControlLabel>
            <SelectAllCheckbox
              label='Select All Store Category'
              onChecked={(selected) => {
                state.form.setValue('merchant_categories', state?.storeCategory?.data?.content!)
                state.form.setValue('merchants', state?.store?.data?.content!)
              }}
              onUnChecked={() => {
                state.form.setValue('merchant_categories', [])
                state.form.setValue('merchants', [])
              }}
              checked={
                (state.form.watch('merchant_categories').length === state.storeCategory.data?.content?.length) &&
                (state.form.watch('merchants').length === state.store.data?.content?.length)
              }
              options={state.storeCategory.data?.content}
              selectOption={(_) => ({
                label: _.category_name!,
                value: _.id!,
              })}
              disabled={isEmpty(state.form.watch('directory'))}
            />
            <ControlLabel label='Store Category'>
              <InputSelectAsync
                placeholder='Select store category'
                disabled={!state.state.directory?.id}
                fetchApi={getStoreCategory}
                payload={{directory_ids: [state.state.directory?.id], statuses: ['ACTIVE']}}
                onChangeValue={state.action.handleSyncStoreCategory}
                isMulti={true}
                form={state.form}
                name='merchant_categories'
                selectOption={(_) => ({
                  label: _.category_name!,
                  value: _.id!,
                })}
              />
            </ControlLabel>
            
            <ControlLabel label='Store'>
              <InputSelect
                onChangeValue={state.action.handleSyncStore}
                placeholder='Select store'
                isMulti={true}
                options={state.store.data?.content}
                rule={{required: true}}
                form={state.form}
                name='merchants'
                selectOption={(_) => ({
                  label: _.merchant_name!,
                  value: _.id!,
                })}
              />
            </ControlLabel>
            <ControlLabel label='Payment Method'>
              <InputSelectAsync
                placeholder='Type to add'
                fetchApi={getPaymentMethod}
                payload={{statuses: ['ACTIVE']}}
                isMulti={true}
                form={state.form}
                name='payment_methods'
                selectOption={(_) => ({
                  label: _.method_name!,
                  value: _.id,
                })}
              />
            </ControlLabel>
          </Stack>
          <ContentAction mutation={state.saveCampaign}>
            <Button>Cancel</Button>
            <Button onClick={state.action.handleSubmit}>Save Campaign</Button>
          </ContentAction>
        </HookFormProvider>
      </Content>
    </>
  )
}
