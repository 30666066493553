import { twMerge } from "tailwind-merge";
import { createElement } from "react";

import { TableCellChildrenProps, TableCellProps } from "./TableCell.types";
import { SizeVariant, Theme } from "./TableCell.theme";
import { Divider } from "../Divider/Divider.page";
import { SortAscIcon, SortDescIcon } from "../../Icons";
import { Sort } from "src/app/models/api.types";
import { displayValue } from "src/app/utils/react-utils";
import { getRollingSort } from "./TableCell.utils";
import { useTableCellParams } from "./TableCell.hooks";

export function TableCell<T>({
  renderValue,
  validate,
  parentelement,
  placeholder,
  className,
  contentWrapperClassName,
  paramKey,
  children,
  action,
  index,
  order,
  value,
  size,
  ...props
}: TableCellProps<T>) {

  const [searchParams, setSearchParams] = useTableCellParams(paramKey);

  const activeSort = searchParams?.order === order;
  const withSort = !!order;
  const isHead = parentelement === 'thead';
  const element = isHead ? 'th' : 'td';

  const handleToggleSort = () => {
    const rollingSort = getRollingSort({
      params: searchParams,
      paramKey,
      order
    });
    setSearchParams(rollingSort!);
  };

  return createElement(element, {
    ...props,
    onClick: withSort ? handleToggleSort : undefined,
    className: twMerge(
      'relative',
      isHead ? Theme.th : Theme.tr,
      isHead && 'whitespace-nowrap',
      withSort && 'cursor-pointer',
      ['number', 'boolean'].includes(typeof index) && Theme.index,
      action && Theme.action,
      size && SizeVariant[size],
      className
    )
  }, (
    <div className={twMerge("flex items-center", contentWrapperClassName)}>
      {(action) && (
        <Divider
          orientation="vertical"
          className="absolute top-0 left-0 shadow-action"
        />
      )}
      <TableCellChildren
        displayLabeloptions={{ renderValue, validate, placeholder }}
        {...{ paramKey, isHead, action, value, index }}
      >
        {children}
      </TableCellChildren>
      {(withSort) && (
        <span className="space-y-0.5 inline-block px-2">
          <SortDescIcon className={twMerge(activeSort && !!searchParams.sort && searchParams.sort !== Sort.DESC && "invisible")} />
          <SortAscIcon className={twMerge(activeSort && !!searchParams.sort && searchParams.sort !== Sort.ASC && "invisible")} />
        </span>
      )}
    </div>
  ));
}

function TableCellChildren<T>(props: TableCellChildrenProps<T>) {

  const [searchParams] = useTableCellParams<'page' | 'limit'>(props.paramKey);
  const { limit = 0, page = 0 } = searchParams;

  if (props.isHead) {
    if (props.index === true) {
      return <>No</>;
    }
    if (props.action === true) {
      return <>Action</>
    }
  }
  if (typeof (props.index) === 'number') {
    return <>{(+limit * (+page - 1)) + (props.index + 1)}</>
  }
  if (!props.children) {
    return <>{displayValue(props.value!, props.displayLabeloptions)}</>;
  }
  return <>{props.children}</>;
}
